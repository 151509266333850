import React from 'react'
import CourseName from './CourseName';
import BasicTabs from './BasicTabs';
import VideoPlayer from './VideoPlayer';
import { Button } from '@mui/material';
import Comments from './Comments';


function Course() {
  return (
    <div>
      <div className='container mt-4'>
        <CourseName />
        <div className='row mt-4 reverse-col'>
          <div className='col-md-8 p-2'>
            <div className='basic-tabs'>
              <BasicTabs />
            </div>
          </div>
          <div className='col-md-4 p-2'>
            <div className='basic-tabs'>
              <VideoPlayer/>
              <div className='px-4 py-4 video-details'>
                <h1>Applied Machine Learning Online Course</h1>
                <span>Category: AI & Machine Learning</span>
                <p>For our Instructor led programs in Data Science & ML</p>
                <Button fullWidth variant="contained">Register Here</Button>
              </div>
            </div>
          </div>  
        </div>

        <div className='row'>
          <Comments/>
        </div>
      </div>
    </div>
  )
}

export default Course