import React from 'react'
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';


function Comments() {
  return (
    <>
        <div className='col-sm-5 col-md-6 col-lg-4 p-2'>
            <div className='py-4 video-details'>
              <h1>2364 Comments(s)</h1>
              <span style={{ cursor: 'pointer' }}>Login to comment</span>
            </div>
          </div>
          <div className='col-sm-7 col-md-6 col-lg-4 p-2'>
            <div className='py-4 '>

          <TextField style={{ paddingRight : '10px'}} size="small" id="outlined-basic" label="Search videos" variant="outlined" />
          <Button style={{ paddingTop : '8px'}} variant="contained">Search</Button>
            </div>
          </div>
    </>
  )
}

export default Comments