import React from 'react'

function Header() {
  return (
    <div className='header'>
      <header className='container'>
        <div className='row pt-1'>
          <div className='col-lg-9 col-md-9 header-none-sm'>
            <span>Have a question?</span>
            <span>+91-00000000000</span>
            <span>+91-00000000000</span>
            <span>hello@hello.com</span>
          </div>
          <div className='col-lg-3 col-md-3 col-sm-12 d-flex flex-row-reverse'>
            <span className=''>Login</span>
            <span className=''>Register</span>
          </div>

        </div>
      </header>
    </div>

  )
}

export default Header