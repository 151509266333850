import React from 'react'

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';


function Breadcrumb() {
  return (
    <div className='p-1 mt-2'>
      <div className='container'>
        <div className='row'>
          <div className='col-9'>
            <Breadcrumbs aria-label="breadcrumb" className='pt-2'>
              <Link underline="hover" color="inherit" href="#">
                Home
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href="#"
              >
                Course
              </Link>
              <Typography color="text.primary">Applied ML</Typography>
            </Breadcrumbs>
          </div>
          <div className='col-3 d-flex flex-row-reverse'>
            <TextField size="small" id="outlined-basic" label="Search videos" variant="outlined" />
          </div>
        </div>

      </div>
    </div>


  );
}

export default Breadcrumb

