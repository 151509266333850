import React from 'react'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import user from '../assets/user.png'

function Instructor() {
    return (
        <div className='container-fluid row'>
            <div className='col-sm-4'>
                <div className='profile-pic'>
                    <img alt = 'dp' src={user}></img>
                </div>
            </div>
            <div className='col-sm-8'>
                <div className='instructor-name-div'>
                    <h5 style={{color: '#939393'}}>Name of Instructor</h5>
                    <p><LinkedInIcon color='primary'/></p>
                </div>
            </div>
            <p style={{color: '#939393', marginTop : 30}}>QUALIFICATION: Masters from IISC Bangalore, PROFESSIONAL EXPERIENCE: 11+ years of Experience( Yahoo Labs, Matherix Labs Co-founder, and Amazon)</p>
        </div>
    )
}

export default Instructor