import React from 'react'

function CourseName() {
  return (
    <div className='course-name mt-3'>
        <h1>Applied Machine Learning Online Course</h1>
        <span>Category: AI & Machine Learning</span>
    </div>
  )
}

export default CourseName