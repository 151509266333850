import React from 'react'
import AdbIcon from '@mui/icons-material/Adb';
import Link from '@mui/material/Link';


function Footer() {
  return (
    <div className='footer'>
        <div className='container'>
        <div className='py-5 row'>
            <div className='col-sm-3 mt-3 mb-4 footer-logo'>
                <AdbIcon/>
                <h5 style={{fontWeight : 600,display: 'inline', marginLeft : '10px'}}>LOGO</h5>
            
            </div>
            <div className='col-sm-3 mt-3'>
                <h5 style={{fontWeight : 600}}>Our Courses</h5>
                <Link underline="none" color="#fff" href="#" >Course 1</Link>
                <Link underline="none" color="#fff" href="#" >Course 2</Link>
                <Link underline="none" color="#fff" href="#" >Course 3</Link>
                <Link underline="none" color="#fff" href="#" >Course 4</Link>
            </div>
            <div className='col-sm-3 mt-3'>
                <h5 style={{fontWeight : 600}}>Contact Us</h5>
                <p>+91-9988776655</p>
                <p>+91-9988776655</p>
                <p>+91-9988776655</p>
                <p>+91-9988776655</p>
            </div>
            <div className='col-sm-3 mt-3'>
                <h5 style={{fontWeight : 600}}>More</h5>
                <Link underline="none" color="#fff" href="#" >Success Story</Link>
                <Link underline="none" color="#fff" href="#" >More - 1</Link>
                <Link underline="none" color="#fff" href="#" >More - 2</Link>
                <Link underline="none" color="#fff" href="#" >More - 3</Link>
            </div>
        </div>
        </div>
        
    </div>
  )
}

export default Footer