import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
function FreeVideos(
    {
        index,
        title,
        assignment,
        subModules,
    }
) {
    return (
        <div>
      <Accordion style={{ boxShadow: 'none' }}>
        <AccordionSummary
          className='accordion-heading margin-acc'
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div>
          <h3 style={{color: '#10C3F8'}} className='module-heading'>{`${title}`}</h3>
          <div style={{display: 'flex',alignItems: 'flex-start'}}>
            <h4 className='module-heading-icons'>{`Checkout our free videos`}</h4>
            <h4 className='module-heading-icons'>{`Assignment: ${assignment}`}</h4>
          </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {subModules.map((datum, i) => {
            return (
              <Accordion key={i} style={{ boxShadow: 'none',}}>
                <AccordionSummary
                  // style={{margin: '0px'}}
                  className='accordion-heading margin-acc-2'
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header-2"
                >
                  <h5 className='submodule-heading'>{`${datum.title}-${i+1}`}</h5>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '0px 10px' }}>
                  <List>
                    {datum.nestedSubModules.map((ele, i) => {
                      return (
                        <>
                          <ListItem style={{ padding: '5px 10px' }} className='submodule-heading-li'>
                            <ListItemIcon >
                              <PlayCircleOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary={`${index + 1}:${i + 1} ${ele.title}`} />
                          </ListItem>
                        </>
                      )
                    })}
                  </List>
                </AccordionDetails>
              </Accordion>
            )

          })}
        </AccordionDetails>
      </Accordion>
      <hr/>
    </div>
    )
}

export default FreeVideos