class FakeDb {
  "curriculum" = [
    {
      title: 'Module',
      description: 'Lorem ipsum dolor sit amet.',
      chapter: '10',
      assignment: '3',
      completed: '10%',
      subModuleTitle: 'Sub-Module',
      subModules: [
        {
          title: 'Sub-Module-1',
          description: 'Lorem ipsum dolor sit amet.',
          nestedSubModules: [
            {
              title: 'Sub-Module-1',
              description: 'Lorem ipsum dolor sit amet.',
            },
            {
              title: 'Sub-Module-2',
              description: 'Lorem ipsum dolor sit amet.',
            }
          ]
        },
        {
          title: 'Sub-Module-2',
          description: 'Lorem ipsum dolor sit amet.',
          nestedSubModules: [
            {
              title: 'Sub-Module-1',
              description: 'Lorem ipsum dolor sit amet.',
            },
            {
              title: 'Sub-Module-2',
              description: 'Lorem ipsum dolor sit amet.',
            }
          ]
        }
      ]
    },
    {
      title: 'Module',
      description: 'Lorem ipsum dolor sit amet.',
      chapter: '10',
      assignment: '3',
      completed: '10%',
      subModuleTitle: 'Sub-Module',
      subModules: [
        {
          title: 'Sub-Module-1',
          description: 'Lorem ipsum dolor sit amet.',
          nestedSubModules: [
            {
              title: 'Sub-Module-1',
              description: 'Lorem ipsum dolor sit amet.',
            },
            {
              title: 'Sub-Module-2',
              description: 'Lorem ipsum dolor sit amet.',
            }
          ]
        },
        {
          title: 'Sub-Module-2',
          description: 'Lorem ipsum dolor sit amet.',
          nestedSubModules: [
            {
              title: 'Sub-Module-1',
              description: 'Lorem ipsum dolor sit amet.',
            },
            {
              title: 'Sub-Module-2',
              description: 'Lorem ipsum dolor sit amet.',
            }
          ]
        }
      ]
    },
    {
      title: 'Module',
      description: 'Lorem ipsum dolor sit amet.',
      chapter: '10',
      assignment: '3',
      completed: '10%',
      subModuleTitle: 'Sub-Module',
      subModules: [
        {
          title: 'Sub-Module-1',
          description: 'Lorem ipsum dolor sit amet.',
          nestedSubModules: [
            {
              title: 'Sub-Module-1',
              description: 'Lorem ipsum dolor sit amet.',
            },
            {
              title: 'Sub-Module-2',
              description: 'Lorem ipsum dolor sit amet.',
            }
          ]
        },
        {
          title: 'Sub-Module-2',
          description: 'Lorem ipsum dolor sit amet.',
          nestedSubModules: [
            {
              title: 'Sub-Module-1',
              description: 'Lorem ipsum dolor sit amet.',
            },
            {
              title: 'Sub-Module-2',
              description: 'Lorem ipsum dolor sit amet.',
            }
          ]
        }
      ]
    },
    {
      title: 'Module',
      description: 'Lorem ipsum dolor sit amet.',
      chapter: '10',
      assignment: '3',
      completed: '10%',
      subModuleTitle: 'Sub-Module',
      subModules: [
        {
          title: 'Sub-Module-1',
          description: 'Lorem ipsum dolor sit amet.',
          nestedSubModules: [
            {
              title: 'Sub-Module-1',
              description: 'Lorem ipsum dolor sit amet.',
            },
            {
              title: 'Sub-Module-2',
              description: 'Lorem ipsum dolor sit amet.',
            }
          ]
        },
        {
          title: 'Sub-Module-2',
          description: 'Lorem ipsum dolor sit amet.',
          nestedSubModules: [
            {
              title: 'Sub-Module-1',
              description: 'Lorem ipsum dolor sit amet.',
            },
            {
              title: 'Sub-Module-2',
              description: 'Lorem ipsum dolor sit amet.',
            }
          ]
        }
      ]
    },
    {
      title: 'Module',
      description: 'Lorem ipsum dolor sit amet.',
      chapter: '10',
      assignment: '3',
      completed: '10%',
      subModuleTitle: 'Sub-Module',
      subModules: [
        {
          title: 'Sub-Module-1',
          description: 'Lorem ipsum dolor sit amet.',
          nestedSubModules: [
            {
              title: 'Sub-Module-1',
              description: 'Lorem ipsum dolor sit amet.',
            },
            {
              title: 'Sub-Module-2',
              description: 'Lorem ipsum dolor sit amet.',
            }
          ]
        },
        {
          title: 'Sub-Module-2',
          description: 'Lorem ipsum dolor sit amet.',
          nestedSubModules: [
            {
              title: 'Sub-Module-1',
              description: 'Lorem ipsum dolor sit amet.',
            },
            {
              title: 'Sub-Module-2',
              description: 'Lorem ipsum dolor sit amet.',
            }
          ]
        }
      ]
    },
    {
      title: 'Module',
      description: 'Lorem ipsum dolor sit amet.',
      chapter: '10',
      assignment: '3',
      completed: '10%',
      subModuleTitle: 'Sub-Module',
      subModules: [
        {
          title: 'Sub-Module-1',
          description: 'Lorem ipsum dolor sit amet.',
          nestedSubModules: [
            {
              title: 'Sub-Module-1',
              description: 'Lorem ipsum dolor sit amet.',
            },
            {
              title: 'Sub-Module-2',
              description: 'Lorem ipsum dolor sit amet.',
            }
          ]
        },
        {
          title: 'Sub-Module-2',
          description: 'Lorem ipsum dolor sit amet.',
          nestedSubModules: [
            {
              title: 'Sub-Module-1',
              description: 'Lorem ipsum dolor sit amet.',
            },
            {
              title: 'Sub-Module-2',
              description: 'Lorem ipsum dolor sit amet.',
            }
          ]
        }
      ]
    },
    {
      title: 'Module',
      description: 'Lorem ipsum dolor sit amet.',
      chapter: '10',
      assignment: '3',
      completed: '10%',
      subModuleTitle: 'Sub-Module',
      subModules: [
        {
          title: 'Sub-Module-1',
          description: 'Lorem ipsum dolor sit amet.',
          nestedSubModules: [
            {
              title: 'Sub-Module-1',
              description: 'Lorem ipsum dolor sit amet.',
            },
            {
              title: 'Sub-Module-2',
              description: 'Lorem ipsum dolor sit amet.',
            }
          ]
        },
        {
          title: 'Sub-Module-2',
          description: 'Lorem ipsum dolor sit amet.',
          nestedSubModules: [
            {
              title: 'Sub-Module-1',
              description: 'Lorem ipsum dolor sit amet.',
            },
            {
              title: 'Sub-Module-2',
              description: 'Lorem ipsum dolor sit amet.',
            }
          ]
        }
      ]
    },
    {
      title: 'Module',
      description: 'Lorem ipsum dolor sit amet.',
      chapter: '10',
      assignment: '3',
      completed: '10%',
      subModuleTitle: 'Sub-Module',
      subModules: [
        {
          title: 'Sub-Module-1',
          description: 'Lorem ipsum dolor sit amet.',
          nestedSubModules: [
            {
              title: 'Sub-Module-1',
              description: 'Lorem ipsum dolor sit amet.',
            },
            {
              title: 'Sub-Module-2',
              description: 'Lorem ipsum dolor sit amet.',
            }
          ]
        },
        {
          title: 'Sub-Module-2',
          description: 'Lorem ipsum dolor sit amet.',
          nestedSubModules: [
            {
              title: 'Sub-Module-1',
              description: 'Lorem ipsum dolor sit amet.',
            },
            {
              title: 'Sub-Module-2',
              description: 'Lorem ipsum dolor sit amet.',
            }
          ]
        }
      ]
    },
    {
      title: 'Module',
      description: 'Lorem ipsum dolor sit amet.',
      chapter: '10',
      assignment: '3',
      completed: '10%',
      subModuleTitle: 'Sub-Module',
      subModules: [
        {
          title: 'Sub-Module-1',
          description: 'Lorem ipsum dolor sit amet.',
          nestedSubModules: [
            {
              title: 'Sub-Module-1',
              description: 'Lorem ipsum dolor sit amet.',
            },
            {
              title: 'Sub-Module-2',
              description: 'Lorem ipsum dolor sit amet.',
            }
          ]
        },
        {
          title: 'Sub-Module-2',
          description: 'Lorem ipsum dolor sit amet.',
          nestedSubModules: [
            {
              title: 'Sub-Module-1',
              description: 'Lorem ipsum dolor sit amet.',
            },
            {
              title: 'Sub-Module-2',
              description: 'Lorem ipsum dolor sit amet.',
            }
          ]
        }
      ]
    },
    {
      title: 'Module',
      description: 'Lorem ipsum dolor sit amet.',
      chapter: '10',
      assignment: '3',
      completed: '10%',
      subModuleTitle: 'Sub-Module',
      subModules: [
        {
          title: 'Sub-Module-1',
          description: 'Lorem ipsum dolor sit amet.',
          nestedSubModules: [
            {
              title: 'Sub-Module-1',
              description: 'Lorem ipsum dolor sit amet.',
            },
            {
              title: 'Sub-Module-2',
              description: 'Lorem ipsum dolor sit amet.',
            }
          ]
        },
        {
          title: 'Sub-Module-2',
          description: 'Lorem ipsum dolor sit amet.',
          nestedSubModules: [
            {
              title: 'Sub-Module-1',
              description: 'Lorem ipsum dolor sit amet.',
            },
            {
              title: 'Sub-Module-2',
              description: 'Lorem ipsum dolor sit amet.',
            }
          ]
        }
      ]
    },
  ]
  "freeVideos" = [
    {
      title : 'Free Videos',
      assignment: '3',
      subModules: [
        {
          title: 'Sub-Module',
          description: 'Lorem ipsum dolor sit amet.',
          nestedSubModules: [
            {
              title: 'Sub-Module-1',
              description: 'Lorem ipsum dolor sit amet.',
            },
            {
              title: 'Sub-Module',
              description: 'Lorem ipsum dolor sit amet.',
            }
          ]
        },
        {
          title: 'Sub-Module',
          description: 'Lorem ipsum dolor sit amet.',
          nestedSubModules: [
            {
              title: 'Sub-Module-1',
              description: 'Lorem ipsum dolor sit amet.',
            },
            {
              title: 'Sub-Module-2',
              description: 'Lorem ipsum dolor sit amet.',
            }
          ]
        },
        {
          title: 'Sub-Module',
          description: 'Lorem ipsum dolor sit amet.',
          nestedSubModules: [
            {
              title: 'Sub-Module-1',
              description: 'Lorem ipsum dolor sit amet.',
            },
            {
              title: 'Sub-Module-2',
              description: 'Lorem ipsum dolor sit amet.',
            }
          ]
        },
        {
          title: 'Sub-Module',
          description: 'Lorem ipsum dolor sit amet.',
          nestedSubModules: [
            {
              title: 'Sub-Module-1',
              description: 'Lorem ipsum dolor sit amet.',
            },
            {
              title: 'Sub-Module-2',
              description: 'Lorem ipsum dolor sit amet.',
            }
          ]
        },
        {
          title: 'Sub-Module',
          description: 'Lorem ipsum dolor sit amet.',
          nestedSubModules: [
            {
              title: 'Sub-Module-1',
              description: 'Lorem ipsum dolor sit amet.',
            },
            {
              title: 'Sub-Module-2',
              description: 'Lorem ipsum dolor sit amet.',
            }
          ]
        },
        {
          title: 'Sub-Module',
          description: 'Lorem ipsum dolor sit amet.',
          nestedSubModules: [
            {
              title: 'Sub-Module-1',
              description: 'Lorem ipsum dolor sit amet.',
            },
            {
              title: 'Sub-Module-2',
              description: 'Lorem ipsum dolor sit amet.',
            }
          ]
        },
        {
          title: 'Sub-Module',
          description: 'Lorem ipsum dolor sit amet.',
          nestedSubModules: [
            {
              title: 'Sub-Module-1',
              description: 'Lorem ipsum dolor sit amet.',
            },
            {
              title: 'Sub-Module-2',
              description: 'Lorem ipsum dolor sit amet.',
            }
          ]
        },
        {
          title: 'Sub-Module',
          description: 'Lorem ipsum dolor sit amet.',
          nestedSubModules: [
            {
              title: 'Sub-Module-1',
              description: 'Lorem ipsum dolor sit amet.',
            },
            {
              title: 'Sub-Module-2',
              description: 'Lorem ipsum dolor sit amet.',
            }
          ]
        },
      ]
    },
  ]
  "overview" = [
    {
      title: 'Objective of Applied AI/ Machine Learning Online Course',
      description : `The AppliedAICourse attempts to teach students/course-participants some of the core ideas in machine learning, 
      data science and AI that would help the participants go from a real-world business problem to a first cut, working and deployable AI solution to the problem.Our primary focus is to help participants build real-world AI solutions using the skills they learn in this course. 
      This course will focus on practical knowledge more than mathematical or theoretical rigor. That doesn't mean that we would water down the content. We will try and balance the theory and practice while giving more preference to the practical and applied aspects of AI as the course name suggests. 
      Through the course, we will work on 15+ case studies of real-world AI problems and datasets to help students grasp the practical details of building AI solutions. For each idea/algorithm in AI, we would provide examples to provide the intuition and show how the idea to be used in the real world.`,
    },
    {
      title: 'Key Points',
      description : [
        {
          title : `The validity of the Applied Machine Learning course is 365 days from the date of enrollment.`
        },
        {
          title : `Expert Guidance, we will answer your queries in atmost 24hours.`
        },
        {
          title : `A mentor will be assigned to each candidate after the completion of 50% of the course assignments whose sole concentration would be on building the specific student's portfolio/resume and in interview preparation, mock interviews.`
        },
      ],
    },
    {
      title: 'Target Audience:',
      description : `Here is a list of candidates who would benefit from our course: Undergrad (B.Tech/B.E/BSc statistics/Bsc computer science/BCA) students in engineering and science.
      Grad(MS/MTech/ME/MCA) students in engineering and science.
      Working professionals: Software engineers, Business analysts, Startup teams building ML products/services, Product managers, Program managers, Managers, etc.
      Data Analysts, Data Scientists, ML Scientists and ML engineers.`,
    },
  ]
  "courseFeature" = {
    "Lectures" : '600+',
    "Durations" : '150+ hours',
    'Skill Level' : 'All level',
    "Language" : 'English',
    "Assessments" : '30 Mandatory Assignments',
  }

    
}

export default new FakeDb()
