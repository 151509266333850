import React from 'react'
import '../video-react.css'
import { Player } from 'video-react';
function VideoPlayer() {
  return (
    <Player
      playsInline
      poster="/assets/poster.png"
      src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
    />
  )
}

export default VideoPlayer