import './App.css';
import { Routes, Route } from "react-router-dom";
import Navbar from './components/Navbar'
import Header from './components/Header'
import Course from './components/Course'
import Breadcrumb from './components/Breadcrumb'
import Footer from './components/Footer';
import Copyright from './components/Copyright';

function App() {
  return (
    <div className="App">
      <Header/>
      <Navbar/>
      <Breadcrumb/>
      <Routes>
        <Route path="/" element={<Course/>} />
        <Route path="/about" element={<h2>About</h2>} />
      </Routes>
      <Footer/>
      <Copyright/>
    </div>
  );
}

export default App;
