import React from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

function Overview(
    {
        overview,
        courseFeature
    }
) {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-lg-8 border-right'>
                    <h6 style={{ color: 'blue', cursor: 'pointer', fontWeight: 600, textTransform: 'uppercase', fontSize: '14px' }}>Download Syllabus</h6>

                    {overview.map((datum, i) => {
                        if (typeof (datum.description) === 'string') {
                            return (
                                <div className='course-name mt-3'>
                                    <h3 style={{ fontWeight: 600 }}>{datum.title}</h3>
                                    <p style={{ fontWeight: 500, fontSize: '15px' }}>{datum.description}</p>
                                </div>
                            )
                        } else {
                            return (
                                <div className='course-name mt-3'>
                                    <h3 style={{ fontWeight: 600 }}>{datum.title}</h3>
                                    <List className='overview-key-points'>

                                        {datum.description.map((ele, i) => {
                                            return <ListItem>
                                                <ListItemText primary={`${i + 1} ${ele.title}`} />
                                            </ListItem>
                                        })}
                                    </List>
                                </div>
                            )
                        }
                    })}
                    <h6 style={{ color: 'blue', cursor: 'pointer', fontWeight: 500, fontSize: '14px' }}>Please Click Here to Download Our Syllabus</h6>
                </div>
                <div className='col-lg-4'>
                <h6 style={{ fontWeight: 600, fontSize: '18px',textAlign: 'center', marginBottom : '20px' }}>Course Feature</h6>
                    {Object.keys(courseFeature).map((key, i)=>{
                        return (<>
                            <div className='course-feature row '>
                                <span className='col-6'>{key}:</span><span style={{fontWeight :600}} className='col-6'>{courseFeature[key]}</span>
                            </div>
                            <hr className='course-feature-hr'/>
                            </>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Overview